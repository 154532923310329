import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const AllEpisodes = () => (
    <section className="py-5 text-center containe latest-episodes black-band">
      <div className="container">
        <div className="row">
          <div className="col section-header">
            <h1>All Episodes</h1>
          </div>
        </div>
        <div className="row py-5">
          <div className="col-12 col-md-6 col-xl-4">
            <Link to="/episodes/amityville-2-the-possession">
            <div className="episode">
              <div className="episode-wrapper">
                <h1 className="episode-title">Amityville 2: The Possession</h1>
                <h2 className="episode-number">01</h2>
                <div className="episode-body">
                  <p>1982 // Directed by Damiano Damiani // Starring Jack Magner and Diane Franklin</p>
                  <p>In this inaugural episode, Bring Me The Axe dives headfirst into the sequel to the original Amityville Horror phenomenon, 1982's Amityville 2: The Possession. Perhaps the most Italian horror movie made in America at the time, it's a movie about a brother and sister and the really weird, totally inappropriate sexual chemistry between them. And then the haunted stuff happens.</p>
                </div>
                <p className="episode-link">Listen &#62;&#62;</p>
                <div className="episode-poster">
                  <div className="poster-overlay-50">&nbsp;</div>
                  <StaticImage
                    src="../images/amityville-2-the-possession-poster-sm.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1982 horror movie, Amityville 2: The Possession"
                    className="img-fluid"
                    placeholder="BLURRED"
                  />
                </div>
              </div>
            </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
)

export default AllEpisodes